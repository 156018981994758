import styled from "styled-components";

export const StyledPromptBox = styled.section`
  position: fixed;
  z-index: 1000;
  bottom: 75px;
  display: ${({ isMinimized }) => (isMinimized ? 'none' : 'inline-table')}; /* Hide when minimized */
  left: 350px;

  * {
    cursor: ${({ dragging }) => (dragging ? "grabbing" : "grab")};
    font-family: "Helvetica Neue", Helvetica, sans-serif;
  }

  .image-preview-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .image-preview {
    width: 50px; /* Fixed width */
    height: 50px; /* Fixed height */
    object-fit: cover; /* Ensure the image scales properly within the box */
    border: 1px solid #ccc; /* Optional: Add a border for better visibility */
    border-radius: 5px; /* Optional: Rounded corners */
    opacity: 0.5; /* Set opacity to 50% */
  }

  .button-container {
     border-radius: 100px;
     display: flex;
     position: absolute;
     left: 10px;
     bottom: 10px;
     justify-content: flex-start; /* Align buttons to the left or center based on your needs */
     align-items: center;
     gap: 10px; /* Optional: Add space between buttons */
     z-index: 10;
   }

  .start-btn {
    cursor: ${({ isRecording }) => (isRecording ? "not-allowed" : "pointer")};
    opacity: ${({ isRecording }) => (isRecording ? 0.5: 1)};
  }

  .stop-btn {
    cursor: ${({ isRecording }) => (isRecording ? "pointer" : "not-allowed")};
    opacity: ${({ isRecording }) => (isRecording ? 1 : 0.5)};
  }

  .start-read-btn {
    cursor: ${({ allowReading }) => (allowReading ? "pointer" : "not-allowed")};
    opacity: ${({ allowReading }) => (allowReading ? 1 : 0.5)};
  }

  .stop-read-btn {
    cursor: ${({ allowStopReading }) => (allowStopReading ? "pointer" : "not-allowed")};
    opacity: ${({ allowStopReading }) => (allowStopReading ? 1 : 0.5)};
  }

  .upload-btn {
    cursor: ${({ allowUpload }) => (allowUpload ? "pointer" : "not-allowed")};
    opacity: ${({ allowUpload }) => (allowUpload ? 1 : 0.5)};
  }

  .undo-btn {
    cursor: ${({ allowUndo }) => (allowUndo ? "pointer" : "not-allowed")};
    opacity: ${({ allowUndo }) => (allowUndo ? 1 : 0.5)};
  }

  .show-prompt-btn {
    cursor: ${({ allowLastPrompt }) => (allowLastPrompt ? "pointer" : "not-allowed")};
    opacity: ${({ allowLastPrompt }) => (allowLastPrompt ? 1 : 0.5)};
  }

  .start-btn:hover, .stop-btn:hover, .start-read-btn:hover, .stop-read-btn:hover, .upload-btn:hover, .undo-btn:hover, .show-prompt-btn:hover {
    scale: 1.25;
  }

  .send-btn {
    border-radius: 100px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: ${({ prompt }) => (!!prompt ? "pointer" : "not-allowed")};
    opacity: ${({ prompt }) => (!!prompt ? 1 : 0.5)};
  }

  .send-btn:hover {
    scale: 1.25;
  }

  .minimize-btn {
    position: absolute;
    z-index: 1000000;
    right: 5px;
    top: 10px;
    background-color: white;
    border-radius: 3px;
    padding: 10px;
    cursor: pointer;
  }

  .minimize-btn::before {
    display: block;
    content: "";
    width: 15px;
    height: 2px;
    background-color: #333;
  }

  .minimize-btn:hover {
    background-color: #e0e0e0;
  }

  main {
    display: flex;
    flex-direction: column;
    width: min-content;
    background-color: white;
    border: 2px solid #333;
    border-radius: 10px;
    padding-bottom: 50px;
  }

  main span {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: ${({ height }) => (!!height ? height* 2 : 200)}px;
    overflow: auto;
    font-size: 11pt;
  }

  main span .prompt-msg-row {
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-items: center;
    align-items: flex-start;
    font-size: 11pt;
  }

  /* Paragraphs */
main span .prompt-msg-row p {
  margin: 1em 0;
}

/* Headings */
main span .prompt-msg-row h1 {
  font-size: 2em;
  margin: 0.67em 0;
  font-weight: bold;
}

main span .prompt-msg-row h2 {
  font-size: 1.75em;
  margin: 0.83em 0;
  font-weight: bold;
}

main span .prompt-msg-row h3 {
  font-size: 1.5em;
  margin: 1em 0;
  font-weight: bold;
}

main span .prompt-msg-row h4 {
  font-size: 1.25em;
  margin: 1.33em 0;
  font-weight: bold;
}

main span .prompt-msg-row h5 {
  font-size: 1em;
  margin: 1.67em 0;
  font-weight: bold;
}

main span .prompt-msg-row h6 {
  font-size: 0.85em;
  margin: 2.33em 0;
  font-weight: bold;
}

/* Lists */
main span .prompt-msg-row ul,
main span .prompt-msg-row ol {
  margin: 1em 0 1em 1.5em;
  padding: 0;
}

main span .prompt-msg-row ul {
  list-style-type: disc;
}

main span .prompt-msg-row ol {
  list-style-type: decimal;
}

main span .prompt-msg-row li {
  margin-bottom: 0.5em;
}

/* Blockquotes */
main span .prompt-msg-row blockquote {
  margin: 1em 0;
  padding-left: 1em;
  border-left: 4px solid #ccc;
  color: #666;
  font-style: italic;
}

/* Inline Code */
main span .prompt-msg-row code {
  font-family: 'Courier New', Courier, monospace;
  background-color: #f9f9f9;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-size: 0.95em;
}

/* Code Blocks */
main span .prompt-msg-row pre {
  background-color: #f9f9f9;
  padding: 1em;
  overflow: auto;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.95em;
}

/* Horizontal Rules */
main span .prompt-msg-row hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 1.5em 0;
}

/* Links */
main span .prompt-msg-row a {
  color: #007bff;
  text-decoration: none;
}

main span .prompt-msg-row a:hover {
  text-decoration: underline;
}

/* Images */
main span .prompt-msg-row img {
  max-width: 100%;
  height: auto;
}

/* Tables */
main span .prompt-msg-row table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

main span .prompt-msg-row th,
main span .prompt-msg-row td {
  border: 1px solid #ddd;
  padding: 0.5em;
}

main span .prompt-msg-row th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Definition Lists */
main span .prompt-msg-row dl {
  margin: 1em 0;
}

main span .prompt-msg-row dt {
  font-weight: bold;
}

main span .prompt-msg-row dd {
  margin-left: 1em;
}

/* Abbreviations */
main span .prompt-msg-row abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

/* Address */
main span .prompt-msg-row address {
  font-style: normal;
  margin: 1em 0;
}

/* Inline Quotes */
main span .prompt-msg-row q {
  quotes: "“" "”" "‘" "’";
}

main span .prompt-msg-row q::before {
  content: open-quote;
}

main span .prompt-msg-row q::after {
  content: close-quote;
}

/* Superscript and Subscript */
main span .prompt-msg-row sup,
main span .prompt-msg-row sub {
  font-size: 0.8em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

main span .prompt-msg-row sup {
  top: -0.5em;
}

main span .prompt-msg-row sub {
  bottom: -0.25em;
}

/* Small Text */
main span .prompt-msg-row small {
  font-size: 0.8em;
}

/* Strong and Emphasis */
main span .prompt-msg-row strong {
  font-weight: bold;
}

main span .prompt-msg-row em {
  font-style: italic;
}

/* Marked Text */
main span .prompt-msg-row mark {
  background-color: #ff0;
  color: #000;
}

/* Figures */
main span .prompt-msg-row figure {
  margin: 1em 0;
}

main span .prompt-msg-row figcaption {
  font-size: 0.9em;
  text-align: center;
  color: #666;
}

/* Buttons */
main span .prompt-msg-row button {
  font-size: 1em;
  padding: 0.5em 1em;
}

/* Form Elements */
main span .prompt-msg-row input,
main span .prompt-msg-row textarea,
main span .prompt-msg-row select {
  font-size: 1em;
  margin: 0.5em 0;
}

/* Details and Summary */
main span .prompt-msg-row details {
  margin: 1em 0;
}

main span .prompt-msg-row summary {
  font-weight: bold;
  cursor: pointer;
}

/* Styling for Nested Lists */
main span .prompt-msg-row ul ul,
main span .prompt-msg-row ol ol,
main span .prompt-msg-row ul ol,
main span .prompt-msg-row ol ul {
  margin-left: 1em;
}

/* Reset list-style for nested lists */
main span .prompt-msg-row ul ul,
main span .prompt-msg-row ol ul {
  list-style-type: circle;
}

main span .prompt-msg-row ul ol,
main span .prompt-msg-row ol ol {
  list-style-type: lower-alpha;
}

  main span .prompt-msg-row:first-child {
    padding-top: 10px;
  }

  main span .prompt-msg-row:last-child {
    padding-bottom: 10px;
  }

  main span .prompt-msg-row * {
    font-size: 11pt;
  }

  main span .prompt-msg-row img {
    align-self: flex-start;
    margin-top: 3px;
    user-select: none;
    pointer-events: none;
  }

  textarea {
    width: ${({ width }) => (!!width ? width : 400)}px;
    min-width: 400px;
    height: ${({ height }) => (!!height ? height : 125)}px;
    min-height: 125px;
    max-height: 20vh;
    resize: both;
    outline: none;
    padding: 10px;
    padding-bottom: 50px;
    border: none;
    border-radius: 10px;
    ${({ hasHistory }) =>
      hasHistory
        ? `
	    border-top-left-radius: 0;
	    border-top-right-radius: 0;
            border-top: 1px solid #333;
        `
        : ""}
  }

  select {
    position: absolute;
    background-color: transparent;
    right: 0;
    bottom: -26px;
    height: 26px;
    outline: none;
    border: none;
  }

  textarea::placeholder {
    opacity: 0.5;
    font-size: 10pt;
  }

  textarea::-ms-input-placeholder {
    opacity: 0.5;
  }

  .info-box-container .img {
    position: absolute;
    right: -15px;
    top: -45px;
    cursor: pointer;
    padding: 24px;
  }

  .info-box-container:hover .info-box {
    display: block;
  }

  .info-row {
    font-size: 12pt;
    font-weight: bold;
    color: #53b2ff;
    margin-top: 40px;
  }

  .info-header {
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
  }

  .info-header img {
    align-self: flex-start;
    margin-right: 10px;
    margin-top: -10px;
  }

  .info-content {
    position: relative;
    display: block;
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
  }

  .info-header hr {
    width: 95%;
    border: 2px solid #b7b7b7;
  }

  .info-box {
    position: absolute;
    display: none;
    width: 600px;
    max-height: 75vh;
    padding: 15px;
    background-color: white;
    border: 2px solid #333;
    border-radius: 10px;
    font-size: 10pt;

    bottom: ${({ height }) => `${(height || 75) + 25}px`};
    left: ${({ width }) => `${ width || 415}px`};
  }
`;

export const StyledShowPromptButton = styled.button`
  background-color: #855CD6;
  color: white;
  border-radius: 3px;
  padding: 6px;
  cursor: pointer;
  position: absolute;
  z-index: 1001;
  top: 6.25rem;
  left: 20rem;
  font-size: 12px; /* Set font size to 12px */
  font-family: 'Helvetica', sans-serif; /* Use Helvetica as the font family */
  font-weight: bold; /* Set the font weight to bold */
`;
